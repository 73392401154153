import React from "react";
import billClegg from "assets/Bill Clegg.jpg";
import dylan from "assets/Dylan McDermott.jpg";
import alan from "assets/Alan J. Budney.jpg";
import deepak from "assets/Deepak Cyril DSouza.jpg";
import judith from "assets/Judith Grisel, PHD.jpg";
import consuelo from "assets/Consuelo Mack.jpg";

export default {
  heading: "Program",
  sections: [
    {
      heading: "2020 Fountain House Humanitarian Award Recipient",
      items: [
        {
          image: billClegg,
          heading: "Bill Clegg",
          description: (
            <>
              Author of <em>Portrait of an Addict as a Young Man</em> and the
              forthcoming novel <em>The End of the Day</em>
            </>
          ),
        },
      ],
    },
    {
      heading: "2020 Fountain House Advocacy Award Recipient",
      items: [
        {
          image: dylan,
          heading: "Dylan McDermott",
          description:
            "Golden Globe® award-winning actor Emmy® nominee and star of the new Netflix series ‘Hollywood’",
        },
      ],
    },
    {
      heading: "2020 Panelists",
      items: [
        {
          image: alan,
          heading: "Alan J. Budney, PHD",
          description: (
            <>
              <p>
                Professor in Psychiatry at the Center for Technology and
                Behavioral Health Geisel School of Medicine at Dartmouth
              </p>
              <p>
                Director of the T32 Training Program in the Science of
                Co-Occurring Disorders
              </p>
            </>
          ),
        },
        {
          image: deepak,
          heading: "Deepak Cyril D'Souza, MBBS, MD",
          description: (
            <>
              <p>
                Professor of Psychiatry at the Yale University School of
                Medicine
              </p>
              <p>
                Director of the Schizophrenia Neuropharmacology Research Group
                at Yale (SNRGY)
              </p>
            </>
          ),
        },
        {
          image: judith,
          heading: "Judith Grisel, PHD",
          description: (
            <>
              <p>
                Professor of Psychology and Neuroscience at Bucknell University
              </p>
              <p>
                Author of{" "}
                <em>
                  Never Enough:The Neuroscience and Experience of Addiction
                </em>{" "}
                (2019)
              </p>
            </>
          ),
        },
      ],
    },
    {
      heading: "Master of Ceremonies",
      items: [
        {
          image: consuelo,
          heading: "Consuelo Mack",
          description: (
            <>
              Founder, Executive Producer and Anchor{" "}
              <em>Consuelo Mack WealthTrack</em>
            </>
          ),
        },
      ],
    },
  ],
};
