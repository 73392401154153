import React from "react";
import styles from "./people.module.scss";
import Person from "components/person";

const People = ({ heading, items }) => {
  return (
    <div className={styles.element}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.people}>
        {items.map((props) => (
          <Person key={props.heading} {...props} />
        ))}
      </div>
    </div>
  );
};

export default People;
