import React from "react";
import mutual from "assets/Mutual of America.svg";
import pathstone from "assets/Pathstone.jpg";
import gallagher from "assets/Gallagher.jpg";
import styles from "./sponsors.module.scss";
import cornell from "assets/Weil Cornell Medicine.png";
import cravath from "assets/Cravath.jpg";
const Sponsors = () => {
  return (
    <div className={styles.element}>
      <div className={styles.heading}>Our Corporate Sponsors</div>
      <div className={styles.logos}>
        <img src={pathstone} alt="Pathstone logo" />
        <img src={cravath} alt="Cravath, Swaine & Moore LLP Logo" />
        <img src={cornell} alt="Weil Cornell Medicine logo" />
        <img src={mutual} alt="Mutual of America logo" />
        <img src={gallagher} alt="Gallagher logo" />
      </div>
    </div>
  );
};

export default Sponsors;
