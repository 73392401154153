import React from "react";
import styles from "./section.module.scss";

const Section = ({ className = "", heading, anchor, children, theme }) => {
  return (
    <section id={anchor || heading}>
      <div className={`${styles.element} Section--${theme} ${className}`}>
        <div className={styles.elementInner}>
          <div className={styles.heading}>{heading}</div>
          <div className={styles.inner}>{children}</div>
        </div>
      </div>
    </section>
  );
};

export default Section;
