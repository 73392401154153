import React from "react";
import Section from "components/section";
import data from "./data";
import Listing from "components/listing";
import Sponsors from "components/sponsors";

const Supporters = () => {
  return (
    <Section heading={data.heading} anchor={data.navHeading}>
      <Listing items={data.items} />
      <Sponsors />
    </Section>
  );
};

export default Supporters;
