import React from "react";

export default {
  heading: "What is happening at Fountain House",
  text: (
    <>
      <p>
        Fountain House understands that sustainable recovery from mental illness
        and social isolation takes a community. People living with mental
        illness at Fountain House work together, accompanied by staff, to regain
        confidence, build social connections, learn new skills, and find greater
        independence, dignity, and hope for the future. Fountain House operates
        innovative programs in the areas of employment, education, health and
        wellness, housing, and the arts, as well as partnerships with healthcare
        networks, criminal justice organizations and other services. For these
        reasons, Fountain House is both a form of community-based social therapy
        as well as a traditional social services agency.
      </p>
      <p>
        Because of the effectiveness of its approach and its advocacy efforts,
        Fountain House has become the most widely replicated community-based
        model for people living with mental illness. Today, more than 300
        affiliated programs serve over 100,000 people in the US and in more than
        30 countries - all operated by people with mental illness - helping
        members build self-esteem and live with dignity.
      </p>
    </>
  ),
  link: {
    text: "Learn more",
    url: "https://fountainhouse.org",
  },
};
