import React from "react";
import styles from "./link.module.scss";

const Link = ({ text, url, hover, className = "" }) => {
  return (
    <a
      className={`${styles.element} hover--${hover} ${className}`}
      href={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {text}
    </a>
  );
};

export default Link;
