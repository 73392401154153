import React from "react";
import Section from "components/section";
import data from "./data";
import Link from "components/link";
import Text from "components/text";
import styles from "./donate.module.scss";

const Donate = () => {
  return (
    <Section className={styles.element} heading={data.heading} theme="blue">
      <Text>{data.text}</Text>
      <Link className={styles.link} {...data.link} hover="green" />
    </Section>
  );
};

export default Donate;
