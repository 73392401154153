export default {
  heading: "Leadership",
  items: [
    {
      heading: "Founding Chairs",
      items: ["Lorna Hyde Graev", "Lynn Nicholas, PsyD"],
    },
    {
      heading: "Chairs",
      items: [
        "Patricia Begley",
        "Angela Clofine",
        "Kitty de Chazal",
        "Alexandra A. Herzan",
        "Katherine Lipton",
        "Carolyn B. MacKenzie",
        "Anne Mai",
        "Jennifer Oken",
        "Florence Peyrelongue",
        "Ilyse Wilpon",
      ],
    },
    {
      heading: "Vice Chairs",
      items: [
        "Ann Barish",
        "Frances Beatty and Allen Adler",
        "Deeda Blair",
        "Marvin H. Davidson",
        "Marion and Willem de Vogel",
        "Jackie and Nick Drexel",
        "Paul M. and Jane L. Frank",
        "Jackie and Barry Gosin",
        "Agnes Gund",
        "The Rev. Nancy Hanna",
        "Jane Hartley and Ralph Schlosstein",
        "Mary T. Ingriselli",
        "Isabel and Jerry Jasinowski",
        "Mary Kalikow",
        "Mariana Kaufman",
        "Jurate Kazickas and Roger Altman",
        "Ginny and David Knott",
        "Kate and Carl Lobell",
        "Ambassador John L. Loeb, Jr. and   Sharon Handler Loeb",
        "Bridget and John Macaskill",
        "Chiara and Jamie Mai",
        "Rebecca Mai and David Mitnick",
        "Jane Michaels and Marne Obernauer",
        "Laura and Richard Parsons",
        "Nancy Peretsman and Robert Scully",
        "Pauline B. Pitt",
        "Mary Alice and M. David Sherrill",
        "Kari Tiedemann",
        "Franny and Richard Zorn",
      ],
    },
    {
      heading: "Benefit Committee",
      items: [
        "Barbara Bancroft",
        "Patricia Beard Braga and David Braga",
        "Patricia M. Beilman and David B. Poor",
        "Evelyn Lorentzen Bell",
        "Rachel and Bill Brannan",
        "Katherine Bryan",
        "Dana Buchman",
        "Maggie Bult",
        "Denise LeFrak Calicchio",
        "Nancy Candib",
        "Myrna Chao",
        "Lois Chiles",
        "Marian and James H. Cohen",
        "Cece Cord  ",
        "Rebecca de Kertanguy",
        "Barbara Georgescu",
        "Eleni and Randall Gianopulos",
        "Gail A. Gilbert",
        "Lynn Gilbert",
        "Diana S. Goldin",
        "Marjorie Reed Gordon",
        "Amanda Haynes-Dale",
        "Lori Hoberman",
        "Fernanda Kellogg and Kirk Henckels",
        "Catherine E. Kinsey",
        "Michele Gerber Klein",
        "Karen Klopp",
        "Michael Kovner and Jean Doyen de Montaillou",
        "Elissa Kramer and Jay Newman",
        "Deborah Krulewitch",
        "Lucy Lamphere",
        "Margo Langenberg",
        "Kate Lear",
        "Lee Link",
        "Shari Lusskin, MD",
        "Podie Lynch",
        "Sandra and Don Middleberg",
        "Noel Momsen",
        "Charlotte Moss and Barry Friedberg",
        "Elizabeth P. Munson",
        "Sally Behr Ogden",
        "Elizabeth T. Peabody, LMSW",
        "Annalu Ponti and Geoffrey R. Hoguet",
        "Diana and Charles Revson",
        "Maria and George Roach",
        "Louise Ross",
        "Lyanne Saperstein",
        "Betsy Seidman",
        "Jean Shafiroff",
        "Sydney R. Shuman",
        "Dorothy W. Sprague",
        "Heidi Stamas",
        "Leila Maw Straus",
        "Zach Taylor and Missie Rennie Taylor",
        "Juliana Terian",
        "Barbara Anderson Terry",
        "Mila and Chris Tewell",
        "Elsie N. Vance",
        "Amy and Ivan Wolpert",
        "Karin Wyser-Pratte",
        "Bettina Zilkha",
      ],
    },
  ],
};
