import React from "react";

export default {
  heading: "Thank You",
  text: (
    <>
      This live video has ended. The recording will be accessible until Friday,
      September 18th at 11:59 pm EST. Thank you to all who attended and
      generously donated to support the mental health recovery of our members!
      You can still support Fountain House{" "}
      <a
        href="https://give.fountainhouse.org/give/263221/#!/donation/checkout?c_src=livestream"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      .
    </>
  ),
  link: {
    text: "Donate",
    url:
      "https://give.fountainhouse.org/give/263221/#!/donation/checkout?c_src=livestream",
  },
};
