import React from "react";

export default {
  embed: (
    <iframe
      title="Fountainhouse Virtual Symposium"
      width="560"
      height="315"
      src="https://www.youtube.com/embed/fs3PMadny1Q?autoplay=1"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  ),
  date: "September 14, 2020",
  heading: "Welcome to the Virtual Symposium",
  alert: (
    <div>
      If you are experiencing difficulties streaming the video, please{" "}
      <button
        onClick={() =>
          typeof window !== "undefined" && window.location.reload()
        }
      >
        click here
      </button>{" "}
      to refresh the page. If you are still having issues, please call{" "}
      <a href="tel:14256106413‬">(425) 610-6413‬</a> or email{" "}
      <a href="mailto:stiajoloff@fountainhouse.org">
        stiajoloff@fountainhouse.org
      </a>
    </div>
  ),
};
//call{" "}<a href="tel:12162227722">216-222-7722</a> or
