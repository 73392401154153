import React from "react";
import styles from "./person.module.scss";

const Person = ({ heading, description, image }) => {
  return (
    <div className={styles.element}>
      <img
        className={styles.image}
        src={image}
        alt={`Portrait of ${heading}`}
      />
      <div className={styles.heading}>{heading}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default Person;
