import React from "react";
import styles from "./listing.module.scss";

const Listing = ({ items }) => {
  return (
    <div className={styles.element}>
      {items.map(({ heading, items }) => {
        return (
          <div className={styles.section} key={heading}>
            <div className={styles.heading}>{heading}</div>
            <ul className={styles.list}>
              {items.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Listing;
