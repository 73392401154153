import React from "react";
import data from "./data";
import Section from "components/section";
import Link from "components/link";
import Text from "components/text";
import team from "assets/Group Shot.jpg";
import styles from "./whats-happening.module.scss";

const WhatsHappening = () => {
  return (
    <>
      <Section heading={data.heading}>
        <Text>{data.text}</Text>
        <Link className={styles.link} {...data.link} hover="green" />
      </Section>
      <img src={team} alt="The Fountainhouse team waving at the camera." />
    </>
  );
};

export default WhatsHappening;
