import React from "react";
import styles from "./app.module.scss";
import Nav from "components/nav";
import Hero from "components/hero";
import Leadership from "components/leadership";
import Program from "components/program";
import Donate from "components/donate";
import WhatsHappening from "components/whats-happening";
import Supporters from "components/supporters";
import Footer from "components/footer";
import Aftershow from "components/aftershow";

const App = () => {
  return (
    <div className={styles.element}>
      <Nav />
      <Hero />
      <Aftershow />
      <Leadership />
      <Program />
      <Donate />
      <WhatsHappening />
      <Supporters />
      <Footer />
    </div>
  );
};

export default App;
