export default {
  heading: "Support",
  text:
    "Your tax-deductible donation helps Fountain House continue its life-saving work. In response to the COVID-19 pandemic, Fountain House has transitioned to digital and home-based services to care for its 2,000 members living with mental illness. These resources offer our members 24/7 access to the social networks, counseling, and collective work vital to their well-being and recovery.",
  link: {
    text: "Donate",
    url:
      "https://give.fountainhouse.org/give/263221/#!/donation/checkout?c_src=livestream",
  },
};
