import React from "react";
import logo from "assets/fountainhouse.svg";
import styles from "./footer.module.scss";
import twitter from "assets/Twitter.svg";
import instagram from "assets/Instagram.svg";
import facebook from "assets/Facebook.svg";
const Footer = () => {
  return (
    <footer className={styles.element}>
      <a
        className={styles.logo}
        href={"https://fountainhouse.org"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logo} alt={"Fountain House"} />
      </a>
      <div className={styles.copyright}>
        ©{new Date().getFullYear()} All Rights Reserved
      </div>
      <ul className={styles.social}>
        <li>
          <SocialLink
            title="Twitter"
            icon={twitter}
            url="https://twitter.com/fountainhouse47"
          />
        </li>
        <li>
          <SocialLink
            title="Instagram"
            icon={instagram}
            url="https://www.instagram.com/fountainhouse/"
          />
        </li>
        <li>
          <SocialLink
            title="Facebook"
            icon={facebook}
            url="https://www.facebook.com/fountainhousenyc"
          />
        </li>
      </ul>
    </footer>
  );
};

export default Footer;

const SocialLink = ({ title, icon, url }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img src={icon} alt={title} />
    </a>
  );
};
