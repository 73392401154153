import React from "react";
import Section from "components/section";
import People from "components/people";
import data from "./data";

const Program = () => {
  return (
    <Section heading={data.heading}>
      {data.sections.map((props) => (
        <People key={props.heading} {...props} />
      ))}
    </Section>
  );
};

export default Program;
