import React from "react";

export default {
  heading: "Luncheon Supporters",
  navHeading: "Supporters",
  items: [
    {
      heading: "Benefactors",
      items: [
        "Patricia Begley and George Beane",
        "Lisa and Dick Cashin",
        "Kitty and Guy de Chazal",
        "Judy Finneran",
        "Joan and Jeremy Frost",
        "Alexandra and Paul Herzan",
        "Anne and Vincent Mai",
        "Howard and Marcia Owens",
        "H. van Ameringen Foundation",
      ],
    },
    {
      heading: "Patrons",
      items: [
        "Jackie and Barry Gosin",
        "Lorna and Larry Graev",
        "Knott Family Foundation",
        "Carolyn MacKenzie",
        "Charles and Marilyn Marsden",
        "Henry S. Myers",
        "Lynn and Nick Nicholas",
        "Nathan Saint-Amand",
        <>
          Arlene Lidsky Salomon and
          <br />
          &nbsp;&nbsp;Chester B. Salomon
        </>,
      ],
    },
    {
      heading: "Donors",
      items: [
        "Mary Dale Allen",
        "Altman Kazickas Foundation",
        "Ann and Keith Barish",
        "Bloomberg Philanthropies",
        "Myrna Chao",
        "The Rev. Nancy W. Hanna",
        "Wendy and Bill Hilburn",
        "Laurie and David Hodgson",
        "Mary Kalikow",
        "The Lipton Foundation",
        "Melony and Adam Lewis Fund",
        "Jennifer Oken",
        "Laura and Richard Parsons",
        "Betsy and Rob Pitts",
        "Kathleen E. Shannon",
      ],
    },
    {
      heading: "Sponsors",
      items: [
        "Valia and Gavin A. Anderson",
        "Mercedes T. Bass",
        "BDO USA, LLP",
        "Frances Beatty and Allen Adler",
        "Angela Clofine",
        "Theodate Coates",
        "Cravath, Swaine & Moore LLP",
        "Marion and Willem de Vogel",
        "Nancy L. and Thomas P. Farrell",
        "Agnes Gund",
        "Carl and Patricia Hagberg",
        "Jane Hartley and Ralph Schlosstein",
        "Heather and Jim Johnson",
        "Fran Laserson",
        "Leonard and Judy Lauder",
        "Kate and Carl Lobell",
        "Lois Chiles Foundation",
        "Chiara and Jamie Mai",
        "Rebecca Mai and David Mitnick",
        "Marvin H. Davidson Foundation",
        "Ina Meibach",
        "Bethany and Robert B. Millard",
        "Otsuka America Pharmaceutical, Inc.",
        "Pathstone",
        "Maria and David Patterson",
        "Florence Peyrelongue",
        "Pauline B. Pitt",
        "John S. Pyne",
        "Anonymous",
      ],
    },
    {
      heading: "Friends",
      items: [
        "Eleanor M. Alger",
        "Annette Allen",
        "Norma J. Arnold",
        "Jessica Aronin and Adam Graev",
        "Carol Atkinson",
        "Kathleen Vuillet Augustine",
        "Raquel Baker",
        "Barry Friedberg and Charlotte Moss Foundation",
        "Evelyn Lorentzen Bell",
        "Jennifer Bergstrom",
        "William L. Bernhard",
        "Rachel and Bill Brannan",
        "Jill Braufman",
        "Amy Butte",
        "Kim and Payson Colemen",
        "Cook Maran and Associates",
        "Robert de Rothschild",
        "Jackie and Nick Drexel",
        "Kenn Dudek and Pauline Nicholls",
        "Peggy Dulany",
        "Emilia and J. Pepe Fanjul",
        "Jane C. Gould-Fillo",
        "Susan and Chip Fisher",
        "Elizabeth Ford",
        "Paul M. and Jane L. Frank",
        "Marina Wolkonsky Galesi",
        "Gallagher",
        "Barbara Georgescu",
        "Shirley and David Ginzberg",
        "Diana S. Goldin",
        "Kiendl and John Gordon",
        "Marjorie Reed Gordon",
        "Myrna and Steve Greenberg",
        "Ann Kaplan",
        "Mariana Kaufman",
        "Holly Kelly",
        "Christina Kind",
        "Valerie Kind-Rubin",
        "Catherine E. Kinsey",
        "Jane Parsons Klein",
        "Michele Gerber Klein",
        "Karen Klopp",
        "Sharon and Cary A. Koplin",
        <>
          Michael Kovner and <br />
          &nbsp;&nbsp;Jean Doyen de Montaillou
        </>,
        "Deborah Krulewitch",
        "Margo Langenberg",
        "David N. Lawrence",
        "Kate Lear",
        "Hazel and Robert Lichterman",
        "Terry and Bob Lindsay",
        "Lee and Fritz Link",
        "Susan and Martin Lipton",
        <>
          Ambassador John L. Loeb, Jr. and <br />
          &nbsp;&nbsp;Sharon J. Handler Loeb
        </>,
        "Jamie Marshall",
        "Janet McDavid",
        "Noel Momsen",
        "Diane Morris",
        "Elizabeth P. Munson",
        "Mutual of America",
        "Neuberger Berman LLC",
        "The Obernauer Foundation",
        "Sally Behr Ogden",
        "Dara and Tim O’Hara",
        "Oxford Health Plans",
        "Elizabeth T. Peabody, LMSW",
        "Helen Pilkington",
        "Annalu Ponti and Geoffrey R. Hoguet",
        "Jane H. Poole",
        "Steve and Debbie Prince",
        "William and Susan Rifkin",
        "Maria and George Roach",
        "Dorothy J. Samuels",
        "Elizabeth Scheuer",
        "Nancy Peretsman and Robert Scully",
        "Betsy Seidman",
        "Jean Shafiroff",
        "Barry Shapiro",
        "Mary Alice and M. David Sherrill",
        "Sydney R. Shuman",
        "Dorothy W. Sprague",
        "Theresa and John Sprague",
        "Leila Maw Straus",
        "Lybess Sweezy and Ken Miller",
        "Zach Taylor and Missie Rennie Taylor",
        "Mila and Chris Tewell",
        "Kari Tiedemann",
        "Barbara Tober",
        "Esme Usdan",
        "Weill Cornell Medicine",
        "Ilyse Wilpon",
        "Lyle Wilpon",
        "Reva Wurtzburger",
        "Karin Wyser-Pratte",
        "Wendy Zimmerman and Steve Cutler",
        "Franny and Richard Zorn",
        "Anonymous",
      ],
    },
  ],
};
