import React from "react";
import Section from "components/section";
import data from "./data";
import Listing from "components/listing";

const Leadership = () => {
  return (
    <Section heading={data.heading} theme="lightBlue">
      <Listing items={data.items} />
    </Section>
  );
};

export default Leadership;
