import React from "react";
import styles from "./hero.module.scss";
import data from "./data";

const Hero = () => {
  return (
    <section id={"Watch"}>
      <div className={styles.element}>
        <div className={styles.embedWrap}>
          <div className={styles.embed}>{data.embed}</div>
        </div>
        {/* <div>
          <div className={styles.date}>{data.date}</div>
          <div className={styles.heading}>{data.heading}</div>
        </div> */}
      </div>
      {/* <div className={styles.alert}>{data.alert}</div> */}
    </section>
  );
};

export default Hero;
